import ValidationException from './../../exceptions/validationException.js';
import Validation from './../../utilities/validation.js';

export default class ChangePasswordSection {
    validation = null;
    isValidate = false;
    form = document.getElementById('change-password-form');
    formButton = null;
    formData = {
        newPassword: null,
        newPasswordRepeat: null
    };

    constructor(notification, settings, locale) {
        this.notification = notification;
        this.settings = settings;
        this.locale = locale;
        this.validation = new Validation(homeConfig);
        this.formButton = this.form.querySelector('.auth-form__buttons input');
        this.prepareChangePasswordForm();
        this.bindChangePasswordFormSubmitButton();
    }

    prepareChangePasswordForm = () => {
        let { form, formData } = this;

        for (const inputName in formData) {
            formData[inputName] = form.querySelector(`[name="${inputName}"]`);
        }
    }
    
    bindChangePasswordFormSubmitButton = () => {
        const { formButton, formData } = this;

        formButton.addEventListener('click', async (e) => {
            e.preventDefault();
            await this.validatePasswordForm();

            if (this.isValidate) {

                let body = `Password=${encodeURIComponent(formData.newPassword.value)}&`;
                body += `ConfirmPassword=${encodeURIComponent(formData.newPasswordRepeat.value)}&`;
                body += `UserId=${encodeURIComponent(this.settings("resetPasswordData").userId)}&`;
                body += `Token=${encodeURIComponent(this.settings("resetPasswordData").token)}&`;

                const response = await fetch(this.settings("url").updatePassword, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        'X-Requested': 'With: XMLHttpRequest'
                    }
                });

                const res = await response.json();

                if (res) {
                    this.notification.show(this.notification.types.success, this.locale("updatePasswordSuccess"));
                    modal.close('resetPasswordChange');
                } else {
                    this.notification.show(this.notification.types.error, this.locale("updatePasswordError"));
                }
            }
        });
    }

    validatePasswordForm = () => {
        const { validation } = this;
        let { formData } = this;
        this.isValidate = true;

        return new Promise(async (resolve, reject) => {
            try {
                validation.settings.addMessageUnderInput = true;
                validation.validatePassword(formData.newPassword);
                validation.validateRepeatPassword(formData.newPassword, formData.newPasswordRepeat);
            } catch (error) {
                if (error instanceof ValidationException) {
                    this.changePasswordFormModule.isValidate = false;
                    this.notification.show(this.notification.types.error, error.message);
                }
            } finally {
                resolve();
            }
        });
    }
}